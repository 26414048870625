<template>
  <div>
    <faq-loading v-if="loading"/>
    <div class="faq">
      <div class="wrapper">
        <div class="faq-column">
          <a href="#title-1" class="faq-column__tab title-1">Questions about the auction's functioning</a>
          <a href="#title-2" class="faq-column__tab title-2">Questions about participating in the auctions</a>
          <a href="#title-3" class="faq-column__tab title-3">Questions about seasons</a>
          <a href="#title-4" class="faq-column__tab title-4">Questions about NFTs</a>
          <a href="#title-5" class="faq-column__tab title-5">Various questions</a>
        </div>
        <div class="faq-content">
          <h1 class="faq-title">Frequently asked questions</h1>
          <p class="faq-desc">On this page, we have collected the most common questions related to our project.
              If your question is not listed here, feel free to reach out to us on Discord.
          </p>
          <div class="faq-nav__mob">
            <a href="#title-1" class="faq-column__tab title-1" v-smooth-scroll>Questions about the auction's functioning</a>
            <a href="#title-2" class="faq-column__tab title-2" v-smooth-scroll>Questions about participating in the auctions</a>
            <a href="#title-3" class="faq-column__tab title-3" v-smooth-scroll>Questions about seasons</a>
            <a href="#title-4" class="faq-column__tab title-4" v-smooth-scroll>Questions about NFTs</a>
            <a href="#title-5" class="faq-column__tab title-5" v-smooth-scroll>Various questions</a>
          </div>
          <div class="faq-block" id="title-1">
            <h2 class="faq-block__title">1. Questions about the auction's functioning</h2>
            <div class="faq-block__content">
              <app-accordion>
                <template v-slot:title>
                  <span class="faq-body__name">What crypto wallets can I connect?</span>
                </template>
                <template v-slot:content>
                  <p>You can connect any wallet that supports Tezos.</p>
                </template>
              </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">What is the initial rate of each NFT?</span>
                    </template>
                    <template v-slot:content>
                        <p>The initial rate of each NFT is 5 xtz.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">Do you collect any service fees? </span>
                    </template>
                    <template v-slot:content>
                        <p>No, we do not collect a service fee when placing a bid or withdrawing funds.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">Do users get notifications if a bet is overbid?</span>
                    </template>
                    <template v-slot:content>
                        <p>In the "Notifications" section, users can see all information about the status of their bids.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">Can I get a refund after the auction is over?</span>
                    </template>
                    <template v-slot:content>
                        <p>Refunds are only available if your bid was not the winning one. Auction winners get an NFT.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">What happens if I make a bid in the last minutes of the auction?</span>
                    </template>
                    <template v-slot:content>
                        <p>If you bid when there's less than 10 minutes left in the auction, the auction time will automatically be extended by 10 minutes.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">If something goes wrong, can you pause the auction?</span>
                    </template>
                    <template v-slot:content>
                        <p>No. The longestauctionever project contract does not have a stop or pause option.</p>
                    </template>
                </app-accordion>

                <app-accordion>
                    <template v-slot:title>
                        <span class="faq-body__name">What happens if the site server is down or the domain is unavailable for any reason?</span>
                    </template>
                    <template v-slot:content>
                        <p>As long as Tezos Blockchain exists, this contract will always be available through any Tezos Contract Explorer, no matter if the site is up or down.</p>
                    </template>
                </app-accordion>
            </div>
          </div>

            <div class="faq-block" id="title-2">
                <h2 class="faq-block__title">2. Questions about participating in the auctions</h2>
                <div class="faq-block__content">
                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Why can't I bump my bid by 6 xtz?</span>
                        </template>
                        <template v-slot:content>
                            <p>All bids must be divisible by five.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Can I participate in all three auctions?</span>
                        </template>
                        <template v-slot:content>
                            <p>Yes, you can bid in all three auctions: 1-year, 10-year, and 100-year. However, the number of bids you can place under one account will differ depending on the auction. </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Can I bet the same amount as the participant who is ranked first in the rating?</span>
                        </template>
                        <template v-slot:content>
                            <p>Yes, it's possible in 1-year and 10-year auctions. However, you will still rank below that participant.
                                All bidders who place a bid equal in value to yours will be ranked one place lower than you.
                                <br> There is only 1 winner in the centennial auction. To become a winner, you will have to place the highest bid.
                            </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">How many bids can I make under one account?</span>
                        </template>
                        <template v-slot:content>
                            <p>In a one year auction, users can place 100 bids from one account. In a 10 year auction, 10 bids are allowed,
                                and in a 100 year auction, only one bid from each account is allowed.  </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">What happens if my bid is outbid by another participant?</span>
                        </template>
                        <template v-slot:content>
                            <p>You will be informed in the "Notifications" section. To get back to the leading position, you will need to increase your bid.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">My autumn bid was overbid. Do I have to wait until autumn to increase my bid?</span>
                        </template>
                        <template v-slot:content>
                            <p>When you make your first bid, you are reserving the NFT of a particular season. The time of the year when you increase your
                                bid has no effect since you continue competing for the NFT you reserved with your first bid. </p>
                        </template>
                    </app-accordion>

                </div>
            </div>


            <div class="faq-block" id="title-3">
                <h2 class="faq-block__title">3. Questions about seasons</h2>
                <div class="faq-block__content">
                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">How many seasons are there in the project?</span>
                        </template>
                        <template v-slot:content>
                            <p>In the "longestauctionever" project, there are 4 seasons: spring, summer, autumn, and winter.
                                This reflects the four times of the year that are used to create different illustrations within the NFT auctions.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">What role does the time of year play in the auction process?</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                The time of year affects the NFT season that will be reserved for the participant after the first bid is made.
                                There are different illustrations for each season, and the bidder receives an NFT according to the time of year in which they bid.
                            </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">I want to get a spring NFT but it's winter. What should I do?</span>
                        </template>
                        <template v-slot:content>
                            <p>To get a spring NFT, you have to wait until Spring and make your bid within the period from March 1st to May 31st.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">I live in the southern hemisphere and it's summer. Why does the website indicate a different season?</span>
                        </template>
                        <template v-slot:content>
                            <p>The seasons in the longestauctionever project are given according to the seasons of the northern hemisphere.</p>
                        </template>
                    </app-accordion>

                </div>
            </div>


            <div class="faq-block" id="title-4">
                <h2 class="faq-block__title">4. Questions about NFTs</h2>
                <div class="faq-block__content">
                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">How many NFTs will be released within the project?</span>
                        </template>
                        <template v-slot:content>
                            <p>There will be 111 tokenized NFTs in the project. Participants determine which seasons will be tokenized. </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Who is the creator of the illustrations in the project?</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                Artist Nadia Monko created 444 illustrations for the project.
                            </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">What types of collections are there in the project?</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                In the project, there are four types of collections that can be assembled: <br><br>
                                1.Small Collection: contains all four seasons from the one-year auction. <br>
                                2.Medium Collection: contains all four seasons from the ten-year auction. <br>
                                3.Large Collection: contains all four seasons from the annual auction and all four seasons from the ten-year auction. <br>
                                4.Full Collection: contains all four seasons from the annual auction, all four seasons from the ten-year auction, and one NFT from the century auction.
                            </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Can I compile an NFT collection? </span>
                        </template>
                        <template v-slot:content>
                            <p>You can compile an NFT collection by placing a new bid each season.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Why were 444 illustrations created, but only 111 will be given away?</span>
                        </template>
                        <template v-slot:content>
                            <p>For each of the 111 winners, we created 4 seasons. However, only one of the four illustrations will be given to the winner,
                                ensuring that each participant receives an exclusive and unique NFT.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">How will it be chosen which of the 444 illustrations become NFTs?</span>
                        </template>
                        <template v-slot:content>
                            <p>Auction bidders determine which seasons will be tokenized by bidding at different times of the year.</p>
                        </template>
                    </app-accordion>

                </div>
            </div>

            <div class="faq-block" id="title-5">
                <h2 class="faq-block__title">5. Various questions</h2>
                <div class="faq-block__content">
                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Is it forbidden to use extensions for automatic bid raising? </span>
                        </template>
                        <template v-slot:content>
                            <p>We do not restrict the use of auto-raise extensions.</p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">What are you going to do with all Tezos in 100 years?</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                We don't know yet. We have plenty of time to ponder that question.
                            </p>
                        </template>
                    </app-accordion>

                    <app-accordion>
                        <template v-slot:title>
                            <span class="faq-body__name">Can I trade my NFTs once I get them?</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                Answer: Yes, you can always sell or exchange your NFTs on the secondary market just like any other NFTs.
                            </p>
                        </template>
                    </app-accordion>

                </div>
            </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppAccordion from '../components/AppAccordion.vue'
  import FaqLoading from "../components/Loading/FaqLoading";
  export default {
    name: 'FaqPage',
    components: {FaqLoading, AppAccordion },
    data() {
      return{
        loading: true
      }
    },
    mounted () {
      setTimeout(() => {
        this.loading = false
      }, 2000)
      const sections = document.querySelectorAll(".faq-block");
      const navLi = document.querySelectorAll(".faq-column__tab");
      window.addEventListener('scroll', () => {
        let current = "";
        sections.forEach((section) => {
          const sectionTop = section.offsetTop;
          if (pageYOffset >= sectionTop - 200) {
            current = section.getAttribute("id"); }
        });
        navLi.forEach((li) => {
          li.classList.remove("active");
          if (li.classList.contains(current)) {
            li.classList.add("active");
          }
        });
      })
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          });
        });
      });
    }
  }
</script>
<style scoped lang="scss">
  .faq {
    margin-bottom: 90px;
    .wrapper {
      margin-top: 46px;
      align-items: flex-start;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    }
    &-nav {
      &__mob {
        display: none;
        @media (max-width: 768px) {
          display: block;
          margin-top: 20px;
        }
      }
    }
    &-column {
      position: sticky;
      top: 24px;
      min-width: 200px;
      margin-right: 30px;
      @media (max-width: 768px) {
        display: none;
      }
      &__tab {
        //white-space: nowrap;
        padding: 10px 15px;
        border-radius: 5px 20px 20px 5px;
        display: table;
        text-decoration: none;
        font-size: 20px;
        line-height: 26px;
        text-transform: capitalize;
        color: #000000;
        transition: .35s;
        font-weight: bold;
        &:not(:last-child) {
          margin-bottom: 6px;
        }
        &:hover {
          background: #F8F8F8;
        }
        &.active {
          background: #F8F8F8;
          color: #ED7D37;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            background: #ED7D37;
            border-radius: 1.5px;
            height: calc(100% - 12px);
            width: 3px;
          }
        }
      }
    }
    &-title {
      margin: 0 0 12px;
      font-size: 40px;
      line-height: 50px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 8px;
      }
    }
    &-desc {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
    &-body {
      width: 100%;
      &__name {
        font-size: 22px;
        line-height: 30px;
        color: #000000;
        font-weight: bold;
        text-align: left;
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    &-item {
      &__body {
        p {
          margin: 0;
          padding: 0 30px 20px;
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          @media (max-width: 768px) {
            padding: 0 10px 20px;
          }
        }
      }
    }
    &-block {
      margin-top: 40px;
      @media (max-width: 768px) {
        margin-top: 25px;
      }
      &__title {
        scroll-margin-top: 24px;
        margin: 0 0 16px;
        font-size: 30px;
        line-height: 40px;
        text-transform: capitalize;
        color: #000000;
        @media (max-width: 768px) {
          font-size: 25px;
          line-height: 32px;
        }
      }
    }
  }
</style>
