var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "preloader-main"
  }, [_c('div', {
    staticClass: "preloader-main-left"
  }, _vm._l(3, function (i) {
    return _c('vue-skeleton-loader', {
      key: i,
      staticStyle: {
        "margin-bottom": "10px"
      },
      attrs: {
        "type": "rect",
        "height": 45,
        "rounded": true,
        "radius": 5,
        "width": 203,
        "animation": "wave"
      }
    });
  }), 1), _c('div', {
    staticClass: "preloader-main-right"
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_c('vue-skeleton-loader', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "type": "rect",
      "width": 178,
      "height": 41,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _vm._l(5, function (i) {
    return _c('vue-skeleton-loader', {
      key: i,
      staticClass: "w-full",
      staticStyle: {
        "margin-bottom": "5px"
      },
      attrs: {
        "type": "rect",
        "height": 15,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    });
  })], 2), _vm._l(6, function (it) {
    return _c('div', {
      key: it,
      staticStyle: {
        "margin-bottom": "40px"
      }
    }, [_c('vue-skeleton-loader', {
      staticStyle: {
        "margin-bottom": "12px"
      },
      attrs: {
        "type": "rect",
        "width": 178,
        "height": 41,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _vm._l(4, function (i) {
      return _c('vue-skeleton-loader', {
        key: i,
        staticClass: "w-full",
        staticStyle: {
          "margin-bottom": "15px"
        },
        attrs: {
          "type": "rect",
          "height": 60,
          "rounded": true,
          "radius": 20,
          "animation": "wave"
        }
      });
    })], 2);
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }