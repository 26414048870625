var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('faq-loading') : _vm._e(), _c('div', {
    staticClass: "faq"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "faq-content"
  }, [_c('h1', {
    staticClass: "faq-title"
  }, [_vm._v("Frequently asked questions")]), _c('p', {
    staticClass: "faq-desc"
  }, [_vm._v("On this page, we have collected the most common questions related to our project. If your question is not listed here, feel free to reach out to us on Discord. ")]), _c('div', {
    staticClass: "faq-nav__mob"
  }, [_c('a', {
    directives: [{
      name: "smooth-scroll",
      rawName: "v-smooth-scroll"
    }],
    staticClass: "faq-column__tab title-1",
    attrs: {
      "href": "#title-1"
    }
  }, [_vm._v("Questions about the auction's functioning")]), _c('a', {
    directives: [{
      name: "smooth-scroll",
      rawName: "v-smooth-scroll"
    }],
    staticClass: "faq-column__tab title-2",
    attrs: {
      "href": "#title-2"
    }
  }, [_vm._v("Questions about participating in the auctions")]), _c('a', {
    directives: [{
      name: "smooth-scroll",
      rawName: "v-smooth-scroll"
    }],
    staticClass: "faq-column__tab title-3",
    attrs: {
      "href": "#title-3"
    }
  }, [_vm._v("Questions about seasons")]), _c('a', {
    directives: [{
      name: "smooth-scroll",
      rawName: "v-smooth-scroll"
    }],
    staticClass: "faq-column__tab title-4",
    attrs: {
      "href": "#title-4"
    }
  }, [_vm._v("Questions about NFTs")]), _c('a', {
    directives: [{
      name: "smooth-scroll",
      rawName: "v-smooth-scroll"
    }],
    staticClass: "faq-column__tab title-5",
    attrs: {
      "href": "#title-5"
    }
  }, [_vm._v("Various questions")])]), _c('div', {
    staticClass: "faq-block",
    attrs: {
      "id": "title-1"
    }
  }, [_c('h2', {
    staticClass: "faq-block__title"
  }, [_vm._v("1. Questions about the auction's functioning")]), _c('div', {
    staticClass: "faq-block__content"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What crypto wallets can I connect?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("You can connect any wallet that supports Tezos.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What is the initial rate of each NFT?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("The initial rate of each NFT is 5 xtz.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Do you collect any service fees? ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("No, we do not collect a service fee when placing a bid or withdrawing funds.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Do users get notifications if a bet is overbid?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("In the \"Notifications\" section, users can see all information about the status of their bids.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Can I get a refund after the auction is over?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("Refunds are only available if your bid was not the winning one. Auction winners get an NFT.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What happens if I make a bid in the last minutes of the auction?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("If you bid when there's less than 10 minutes left in the auction, the auction time will automatically be extended by 10 minutes.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("If something goes wrong, can you pause the auction?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("No. The longestauctionever project contract does not have a stop or pause option.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What happens if the site server is down or the domain is unavailable for any reason?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("As long as Tezos Blockchain exists, this contract will always be available through any Tezos Contract Explorer, no matter if the site is up or down.")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "faq-block",
    attrs: {
      "id": "title-2"
    }
  }, [_c('h2', {
    staticClass: "faq-block__title"
  }, [_vm._v("2. Questions about participating in the auctions")]), _c('div', {
    staticClass: "faq-block__content"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Why can't I bump my bid by 6 xtz?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("All bids must be divisible by five.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Can I participate in all three auctions?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("Yes, you can bid in all three auctions: 1-year, 10-year, and 100-year. However, the number of bids you can place under one account will differ depending on the auction. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Can I bet the same amount as the participant who is ranked first in the rating?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("Yes, it's possible in 1-year and 10-year auctions. However, you will still rank below that participant. All bidders who place a bid equal in value to yours will be ranked one place lower than you. "), _c('br'), _vm._v(" There is only 1 winner in the centennial auction. To become a winner, you will have to place the highest bid. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("How many bids can I make under one account?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("In a one year auction, users can place 100 bids from one account. In a 10 year auction, 10 bids are allowed, and in a 100 year auction, only one bid from each account is allowed. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What happens if my bid is outbid by another participant?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("You will be informed in the \"Notifications\" section. To get back to the leading position, you will need to increase your bid.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("My autumn bid was overbid. Do I have to wait until autumn to increase my bid?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("When you make your first bid, you are reserving the NFT of a particular season. The time of the year when you increase your bid has no effect since you continue competing for the NFT you reserved with your first bid. ")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "faq-block",
    attrs: {
      "id": "title-3"
    }
  }, [_c('h2', {
    staticClass: "faq-block__title"
  }, [_vm._v("3. Questions about seasons")]), _c('div', {
    staticClass: "faq-block__content"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("How many seasons are there in the project?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("In the \"longestauctionever\" project, there are 4 seasons: spring, summer, autumn, and winter. This reflects the four times of the year that are used to create different illustrations within the NFT auctions.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What role does the time of year play in the auction process?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" The time of year affects the NFT season that will be reserved for the participant after the first bid is made. There are different illustrations for each season, and the bidder receives an NFT according to the time of year in which they bid. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("I want to get a spring NFT but it's winter. What should I do?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("To get a spring NFT, you have to wait until Spring and make your bid within the period from March 1st to May 31st.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("I live in the southern hemisphere and it's summer. Why does the website indicate a different season?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("The seasons in the longestauctionever project are given according to the seasons of the northern hemisphere.")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "faq-block",
    attrs: {
      "id": "title-4"
    }
  }, [_c('h2', {
    staticClass: "faq-block__title"
  }, [_vm._v("4. Questions about NFTs")]), _c('div', {
    staticClass: "faq-block__content"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("How many NFTs will be released within the project?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("There will be 111 tokenized NFTs in the project. Participants determine which seasons will be tokenized. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Who is the creator of the illustrations in the project?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" Artist Nadia Monko created 444 illustrations for the project. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What types of collections are there in the project?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" In the project, there are four types of collections that can be assembled: "), _c('br'), _c('br'), _vm._v(" 1.Small Collection: contains all four seasons from the one-year auction. "), _c('br'), _vm._v(" 2.Medium Collection: contains all four seasons from the ten-year auction. "), _c('br'), _vm._v(" 3.Large Collection: contains all four seasons from the annual auction and all four seasons from the ten-year auction. "), _c('br'), _vm._v(" 4.Full Collection: contains all four seasons from the annual auction, all four seasons from the ten-year auction, and one NFT from the century auction. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Can I compile an NFT collection? ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("You can compile an NFT collection by placing a new bid each season.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Why were 444 illustrations created, but only 111 will be given away?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("For each of the 111 winners, we created 4 seasons. However, only one of the four illustrations will be given to the winner, ensuring that each participant receives an exclusive and unique NFT.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("How will it be chosen which of the 444 illustrations become NFTs?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("Auction bidders determine which seasons will be tokenized by bidding at different times of the year.")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "faq-block",
    attrs: {
      "id": "title-5"
    }
  }, [_c('h2', {
    staticClass: "faq-block__title"
  }, [_vm._v("5. Various questions")]), _c('div', {
    staticClass: "faq-block__content"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Is it forbidden to use extensions for automatic bid raising? ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("We do not restrict the use of auto-raise extensions.")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What are you going to do with all Tezos in 100 years?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" We don't know yet. We have plenty of time to ponder that question. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Can I trade my NFTs once I get them?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" Answer: Yes, you can always sell or exchange your NFTs on the secondary market just like any other NFTs. ")])];
      },
      proxy: true
    }])
  })], 1)])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq-column"
  }, [_c('a', {
    staticClass: "faq-column__tab title-1",
    attrs: {
      "href": "#title-1"
    }
  }, [_vm._v("Questions about the auction's functioning")]), _c('a', {
    staticClass: "faq-column__tab title-2",
    attrs: {
      "href": "#title-2"
    }
  }, [_vm._v("Questions about participating in the auctions")]), _c('a', {
    staticClass: "faq-column__tab title-3",
    attrs: {
      "href": "#title-3"
    }
  }, [_vm._v("Questions about seasons")]), _c('a', {
    staticClass: "faq-column__tab title-4",
    attrs: {
      "href": "#title-4"
    }
  }, [_vm._v("Questions about NFTs")]), _c('a', {
    staticClass: "faq-column__tab title-5",
    attrs: {
      "href": "#title-5"
    }
  }, [_vm._v("Various questions")])]);
}]

export { render, staticRenderFns }